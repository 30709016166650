<template>
  <Main>
    <PlanModal :closable="false" ref="planModal" @onPlanPurchaseComplete="onComplete"/>
  </Main>
</template>

<script>
import { Main } from "@/view/styled.js";
import PlanModal from "@/components/modals/PlanModal/PlanModal.vue";

export default {
  name: "pricing",
  components: { Main, PlanModal },
  computed: {
    planModal: function() {
      return this.$refs.planModal;
    }
  },
  methods: {
    showPlansModal() {
      this.planModal.showPlansModal();
    },
    onComplete() {
      this.planModal.hidePlansModal();
      setTimeout(() => {
        this.$router.replace({path: "/portal/dashboard"});
      }, 300);
    },
  },
  mounted() {
    setTimeout(() => {
      this.showPlansModal();
    }, 500);
  }
}
</script>